import React, { useEffect, useState } from 'react'
import { CircularProgress, Button, Input, Container, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import localStorage from 'localStorage'
import SnackMessage from '../components/snackbar'
import { UserType } from '../consts'
import Nav from '../components/nav'
import { post, clearToken } from '../utils/request'

const Login = () => {
  const [submitting, setSubmitting] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [userType, setUserType] = useState('managers')
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const postLogin = async (u, p) => {
    const res = userType === 'managers' ? await post('managers/sign_in', { email: u, password: p }) : await post('users/sign_in', { email_or_mobile: u, password: p })
    return res
  }

  const login = async (u, p) => {
    clearToken()
    const res = await postLogin(u, p)
    if (res.id) {
      if (res.enterprise_verification) {
        localStorage.setItem('USER_TYPE', 'manager')
      } else {
        localStorage.setItem('USER_TYPE', 'pending manager')
      }
      setResMessage('管理员登录成功')
    } else if (res.ok) {
      localStorage.setItem('USER_TYPE', 'user')
      setResMessage('子用户登录成功')
    } else {
      setResMessage('登录失败')
    }
    setOpen(true)
    setSubmitting(false)
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitting(true)
      login(username, password)
    }
  }

  useEffect(() => {
    localStorage.setItem('DEVICE_TYPE', 'WEB_MANAGER')
    if (localStorage.getItem('USER_TYPE') === UserType.user) {
      navigate('/')
    }
    if (localStorage.getItem('USER_TYPE') === UserType.manager) {
      navigate('/main/overview')
    }
  })

  return (
    <Nav>
      <Container maxWidth="xs">
        <Grid container>
          <Grid item xs className="pt-32">
            <Grid container className="text-base mb-12 text-center m-auto">
              <Grid item xs className="cursor-pointer">
                <span role="presentation" className={userType === 'managers' ? 'font-bold' : ''} onClick={() => setUserType('managers')}>
                  管理员登录
                </span>
              </Grid>
              <Grid item xs className="cursor-pointer">
                <span role="presentation" className={userType === 'users' ? 'font-bold' : ''} onClick={() => setUserType('users')}>
                  子用户登录
                </span>
              </Grid>
            </Grid>
            <Input placeholder="账号" className="mb-6" type="text" value={username} fullWidth onChange={(e) => setUsername(e.target.value)} />
            <Input placeholder="密码" className="mb-10" fullWidth type="password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyPress={onKeyPress} />
            <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              disabled={submitting}
              onClick={() => {
                setSubmitting(true)
                login(username.trim(), password)
              }}
            >
              {submitting ? <CircularProgress size={24} /> : '登录'}
            </Button>
            <Grid container>
              <Grid item xs className="cursor-pointer mt-3 text-left m-auto">
                <span role="presentation" onClick={() => navigate('/signup')}>
                  立即注册
                </span>
              </Grid>
              <Grid item xs className="cursor-pointer mt-3 text-right m-auto">
                <span
                  role="presentation"
                  onClick={() => {
                    navigate('/find_password')
                  }}
                >
                  找回密码
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
      </Container>
    </Nav>
  )
}

export default Login
